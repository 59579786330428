/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  /* background-color: #E7E7E7 !important; */
  /* font-family: "Lusitana" !important; */
/* } */
.navbar {
  height: 9vh;
}
.PrivateSwipeArea {
  width: 0 !important;
}
/* .MuiButtonBase-root {
  font-family: "Lusitana";
} */
/* .css-1nuo49t-MuiButtonBase-root-MuiTab-root {
  /* font-family: "Lusitana" !important; */
/* } */
