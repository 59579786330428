.MuiSlider-root{
    color : #B61B31 !important
}

/* .MuiSlider-dragging{
    color : #C30E2F
} */

/* .Mui-active{
    color : #C30E2F
} */
.MuiTypography-root{
    font-family: "Lusitana" !important;
}