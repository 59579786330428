.input-suggestion{
  /* font-family:'Lato',sans-serif; */
 /* width:28vw; */
height:7vh;
font-size:1rem;
display: flex;
align-items: center ;
}

.autocomplete-dropdown-container{
  position: absolute;
  top:7.5vh; 
  z-index: 1000;
  border-radius: 0 0 2px 2px;
}

.location-search-input{
  margin: 12px;
  border-radius: 10px;
  padding: 10px;
  width:32.6vw;
  height: 50px;
  border-color: white;
  
}

