#root{
height: 100%;
width: 100%;
background-color: #E7E7E7 !important;
}

 html, .root1 {
  height: 100%;
  width: 100%;
  background-color: #E7E7E7 !important;
  background-image: url("backgrounds.png");
  background-size: contain;
  background-repeat: repeat-y;
}
.no-background {
  height: 100%;
  width: 100%;
  background-color: white !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #C30E2F;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C30E2F;
}

.PhoneInputInput {
	background-color: #E7E7E7;
  border: 0;
  padding: 0.5rem;
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	background-color: #E7E7E7;
  padding-left: 0.3rem;
}
.gm-ui-hover-effect{
        opacity:-0.4
  
  }
  .hg-theme-default {
    background-color:"red";
    /* width: 300px; */
    margin: 30px auto 0;
  }

  /* For Cart Transparent Scrollar  */
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: transparent;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

/* @media only screen and (max-width: 599px) {
  html,.root1  {
    background-color: white !important;
    background-image: url("");
    padding-bottom: 60px;
  }
  #root {
    background-color: white !important;
    background-image: url(" ");
    padding-bottom: 60px;
  }

} */
