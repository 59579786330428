body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  width: 100% !important;
  height: 100% !important;
  font-family: "Lusitana", serif !important;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 
    monospace; */
/* } */
