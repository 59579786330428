.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.center-div-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.space-between-div-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.red-color {
  color: rgba(176, 46, 37, 1);
}
.grey-color {
  color: rgba(153, 153, 153, 1);
}

.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-25 {
  font-size: 25px !important;
}

.bold {
  font-weight: 600 !important;
  /* line-height: 15.56px !important; */
}

.divider {
  border-bottom: 1px solid rgba(255, 229, 229, 1);
  margin-bottom: 0.5rem;
}
.cursor-pointer {
  cursor: pointer;
}

.ff-lusitana {
  font-family: lusitana !important;
}

.margin-top {
  margin-top: 10px !important;
}
.margin-bottom {
  margin-bottom: 5px !important;
}
.url {
  text-decoration: underline;
  color: rgba(66, 176, 255, 1);
}
/* @media print {
  body {
    background-color: white !important;
  }
}

.outer-div {
  background-image: none !important;
  /* height: auto !important; */
/* } */

.p-10 {
  padding: 0.6rem;
}
